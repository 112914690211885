<template>
  <div class="base-form-wrapp" style="height: auto;">
    <a-tabs default-active-key="1" @change="activeTab = $event">
      <a-tab-pane key="general" :tab="$t('basics.general_tab')">
        <div class="detail-info__name" :class="{'not-active': organizationStatus === 3}">
          <h2 class="title">{{detailOrganization.name}} {{$t('basics.company')}}
            <button v-if="canDelete && getRole === 1" class="delete-btn" @click="showMoreInfo = !showMoreInfo"><i class="anticon"><more-icon/></i></button>
          </h2>
          <div v-if="showMoreInfo" class="detail-info__more">
            <a href="javascript:void(0);" class="detail-info__more--delete" @click="deleteOrganization">{{$t('organizations.delete_organization')}}</a>
          </div>
        </div>
        <div v-if="showMoreInfo" class="detail-info__more--overlay" @click="showMoreInfo = !showMoreInfo"></div>
        <a-form :form="form" class="detail-form">
          <a-row :class="{'not-active': organizationStatus === 3}">
            <a-col :lg="12">
              <div class="detail-info__column-title">{{$t('organizations.organization_info')}}</div>
              <a-form-item :label="$t('organizations.step_1.organization_event_title')">
                <a-input
                    size="large"
                    :placeholder="$t('organizations.step_1.enter_organization_name')"
                    v-decorator="['name', {rules: [
                    {required: true, message: $t('messages.requiredField')},
                    {max: 255, message: $t('messages.maxLength') + 255}],
                    initialValue: detailOrganization.name}]"
                >
                </a-input>
              </a-form-item>
              <a-form-item class="one-row large-item" v-if="getRole === 1" :label="`${$t('organizations.organization_status')} (${detailOrganization.is_active ? 'Active': 'Inactive'})`">
                <a-switch @change="onChangeSwitcher('is_active', $event)" v-decorator="['is_active', {valuePropName: 'checked', initialValue: detailOrganization.is_active}]" />
              </a-form-item>
              <a-form-item label="Email">
                <a-select
                    size="large"
                    mode="multiple"
                    label-in-value
                    :placeholder="$t('organizations.select_from_list')"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="usersQuery = $event"
                    @blur="usersQuery = ''"
                    v-decorator="['admins', {initialValue: computedManagers}]"
                >
                  <a-select-option v-for="user in filteredUsers" :value="user.value" :key="user.id">
                    {{ user.email }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item class="user-domains-field" :label="$t('organizations.step_1.user_domains')">
                <div class="has-error">
                  <p class="custom-explain ant-form-explain">{{ $t('organizations.domains_warning') }}</p>
                </div>
                <a-select
                    size="large"
                    mode="tags"
                    :placeholder="$t('organizations.step_1.domain_names')"
                    v-decorator="[
                'user_domains',
                {
                  rules: [
                {
                  validator: validateDomains,
                }
              ],
                    initialValue: detailOrganization.user_domains ? detailOrganization.user_domains : []
                }
              ]"
                >
                </a-select>
              </a-form-item>
              <a-form-item class="user-categories-field" :label="$t('organizations.step_1.user_categories')">
                <a-select
                    size="large"
                    mode="tags"
                    label-in-value
                    :placeholder="$t('organizations.select_from_list')"
                    @change="handleChange"
                    v-decorator="[
                'profiles',
                {
                  rules: [
                    {
                      validator: validateProfiles,
                    }
                ],
                    initialValue: detailOrganization.profiles ? Object.keys(detailOrganization.profiles).map(function(index) {
                    return {
                      key: detailOrganization.profiles[index].profile,
                      label: detailOrganization.profiles[index].profile
                      };
                  }) : []
                }
              ]"
                >
                </a-select>
              </a-form-item>
              <a-form-item :label="$t('basics.phone_number')">
                <a-input
                    size="large"
                    :placeholder="$t('organizations.enter_phone_number')"
                    v-decorator="['phone',
                {
                  rules: [
                    {required: true, message: $t('messages.requiredField')},
                    {min: 2, message: $t('messages.minLength') + 2},
                    {max: 255, message: $t('messages.maxLength') + 255},
                    {pattern: new RegExp(/\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})?/), message: $t('messages.invalidPhoneNumber')}
                  ],
                  initialValue: detailOrganization.phone
                }
            ]">
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('organizations.step_1.location')" class="location-input">
                <a-select
                    show-search
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :labelInValue="true"
                    :not-found-content="null"
                    size="large"
                    :placeholder="$t('organizations.step_1.country_city_address')"
                    @search="searchLocation"
                    @select="setLocation"
                    @blur="addressResultsEmpty = false"
                    v-decorator="[
                    'location',
                {rules: [
                    {required: true, message: $t('messages.requiredField')}],
                    initialValue: {value: detailOrganization.location,
                    key: detailOrganization.location ? detailOrganization.location.label ? detailOrganization.location.label : detailOrganization.location : ''}}]"
                >
                  <a-select-option v-for="item in addressResults" :value="`${item.x}|${item.y}|${item.label}`" :key="item.x">
                    {{item.label ? item.label :item }}
                  </a-select-option>
                </a-select>
                <p class="location-error" v-if="addressResultsEmpty">{{ $t('organizations.step_1.invalid_location') }}</p>
              </a-form-item>
              <p class="ant-col ant-form-item-label custom-lable">{{ $t('organizations.step_1.coordinates') }}</p>
              <div class="coordinates-wrapper">
                <a-form-item>
                  <a-input
                      @change="setLatitude"
                      type="number"
                      size="large"
                      placeholder="2640 48 224"
                      v-decorator="[
                  'latitude',
                  {rules: [ {required: true, message: $t('messages.requiredField')} ],
                  initialValue: detailOrganization.location ? detailOrganization.location.latitude : null
                  }
                ]"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-input
                      @change="setLongitude"
                      type="number"
                      size="large"
                      placeholder="2640 48 224"
                      v-decorator="[
                  'longitude',
                  {rules: [ {required: true, message: $t('messages.requiredField')} ],
                  initialValue: detailOrganization.location ? detailOrganization.location.longitude : null
                  }
                ]"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <p v-if="showMap" class="ant-col ant-form-item-label custom-lable">{{ $t('organizations.step_1.pick_on_map') }}</p>
              <LocationMap :show="showMap" :map="map" @openMap="openMap" ref="locationMap" refer="locationMap" @handleMapClick="handleMapClick" :location="location" />
              <a-form-item v-if="getRole === 1" :label="$t('organizations.step_1.link_with_organization')">
                <a-select
                    mode="multiple"
                    size="large"
                    :showArrow="true"
                    :placeholder="$t('organizations.select_from_list')"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="fetchOrganizations"
                    @focus="fetchOrganizations"
                    v-decorator="['linked_organizations', {initialValue: detailOrganization.linked_organizations}]">
                  <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                  <a-select-option v-for="item in organizationsList" :value="item.id" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item :label="$t('basics.logo')" v-if="getRole === 1">
                <a-input
                    size="large"
                    :placeholder="$t('organizations.enter_logo_url')"
                    v-decorator="['logo', {rules: [{validator: validateLogo}], initialValue: detailOrganization.logo}]"
                />
              </a-form-item>

              <div class="column-title">
                  {{ $t('organizations.step_3.two_factor_title') }}
              </div>
              <a-form-item
                class="one-row sm-mb usbc-field"
                :label="$t('organizations.step_3.two_factor_for_admin')"
              >
              <a-switch
                v-decorator="[
                  'two_factor_authentication',
                  {valuePropName: 'checked', initialValue: detailOrganization.two_factor_authentication}
                ]" />
              </a-form-item>

            </a-col>
            <a-col :lg="12">
              <div class="detail-info__column-title">{{ $t('organizations.base_picos_setup') }}</div>
              <a-form-item class="one-row large-item lock-mode-field" :label="$t('organizations.step_2.lock_mode')">
                <a-switch @change="lockModeChange" v-decorator="['is_lock_mode',
            {valuePropName: 'checked', initialValue: detailOrganization.is_lock_mode }]" />
              </a-form-item>
              <div v-if="!lockModeEnabled" class="custom-error-lock-mode has-error">
                <p class="custom-explain ant-form-explain">{{ $t('organizations.step_2.lockModeWarning') }}</p>
              </div>
              <a-form-item :label="$t('organizations.step_2.number_of_picos')">
                <a-input-number
                    size="large"
                    type="number"
                    :min="picoLimit[0]"
                    style="width: 55px"
                    v-decorator="[
                  'number_limit',
                  {rules: [{required: true, message: $t('messages.requiredField')}], initialValue: detailOrganization.number_limit}
                ]"
                >
                </a-input-number>
              </a-form-item>
              <a-form-item class="one-row large-item" :label="$t('organizations.step_2.limit_holding_time')">
                <a-switch @change="onChangeSwitcher('time_limit.duration', $event)" v-decorator="['is_time_limited',
             {valuePropName: 'checked', initialValue: detailOrganization.is_time_limited}]" />
              </a-form-item>
              <a-form-item class="flex-row" :label="$t('organizations.step_2.holding_time')">
                <a-form-item>
                  <a-input-number
                      :disabled="!isTimeLimited"
                      size="large"
                      type="number"
                      :min="0"
                      style="width: 50px;"
                      v-decorator="[
                  'time_limit.duration', {rules:
                   [{required: isTimeLimited, message: $t('messages.requiredField')}],
                   initialValue: detailOrganization.time_limit ? detailOrganization.time_limit.duration : null}]">
                  </a-input-number>
                </a-form-item>
                <a-form-item>
                  <a-select
                      size="large"
                      style="width: 55px"
                      v-decorator="[`time_limit.unit`,
                  {initialValue: detailOrganization.time_limit ? detailOrganization.time_limit.unit : timeUnits[0]}]">
                    <a-select-option v-for="item in Object.values($t('basics.time_units'))" :value="item" :key="item">
                      {{item}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-form-item>
              <a-form-item class="one-row large-item" :label="$t('organizations.step_2.limit_working_radius')">
                <a-switch :disabled="!canSwitchAreaToggle" @change="onChangeSwitcher('area_limit.center', $event)" v-decorator="['is_area_limited',
            {valuePropName: 'checked', initialValue: detailOrganization.is_area_limited }]" />
              </a-form-item>
              <a-form-item  class="large-item flex-row working-radius" :label="$t('organizations.step_2.working_radius')">
                <a-form-item>
                  <span class="area-limit-icon">±</span>
                  <a-input-number
                      :disabled="!isAreaLimited"
                      size="large"
                      type="number"
                      :min="1"
                      style="width: 60px"
                      @change="setRadiusValue"
                      v-decorator="['area_limit.radius',
                  {rules: [
                    {required: !isAreaLimited, message: $t('messages.requiredField')}
                  ],
                  initialValue: detailOrganization.area_limit ? detailOrganization.area_limit.radius : 1
                  }]">
                  </a-input-number>
                </a-form-item>
                <a-form-item>
                  <a-select
                      size="large"
                      @change="setRadiusUnits"
                      style="width: 60px"
                      v-decorator="[`area_limit.unit`,
                  {initialValue: detailOrganization.area_limit ? detailOrganization.area_limit.unit : metricUnits[0]}]">
                    <a-select-option v-for="item in metricUnits" :value="item" :key="item">
                      {{item}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-form-item>
              <div class="details-radius-map">
                <p v-if="showRadiusMap" class="ant-col ant-form-item-label custom-lable">{{ $t('organizations.step_1.pick_radius') }}</p>
                <LocationMap :radiusMap="true" :circle="circle" @openMap="openRadiusMap" :show="showRadiusMap" :map="map" ref="radiusMap" refer="radiusMap" :location="location" />
              </div>
              <a-form-item class="one-row large-item sm-mb" :label="$t('organizations.step_2.gps_tracker')">
                <a-switch @change="gpsChange" v-decorator="['is_gps_on',
            {valuePropName: 'checked', initialValue: detailOrganization.is_gps_on}]" />
              </a-form-item>
              <a-form-item class="one-row large-item sm-mb" :label="$t('organizations.step_2.nfc_reader')">
                <a-switch v-decorator="['is_nfc_reader_on',
            {valuePropName: 'checked', initialValue: detailOrganization.is_nfc_reader_on }]" />
              </a-form-item>
              <a-form-item v-if="getRole === 1" class="flex-row large-item" :label="$t('organizations.step_2.logs_delay')" >
                <a-form-item>
                  <a-input-number
                      size="large"
                      type="number"
                      :min="2"
                      :max="60"
                      style="width: 50px;"
                      v-decorator="['log_delay.duration', {rules: [
                  {required: true, message: $t('messages.requiredField')}],
                  initialValue: detailOrganization.log_delay ? detailOrganization.log_delay.duration : null}]">
                  </a-input-number>
                </a-form-item>
                <a-form-item>
                  <a-input
                      class="not-editable-input"
                      size="large"
                      style="width: 55px"
                      v-decorator="['log_delay.unit',
                  {initialValue: detailOrganization.log_delay ? detailOrganization.log_delay.unit : 's'}]">
                  </a-input>
                </a-form-item>
              </a-form-item>
              <a-form-item class="flex-row large-item" v-if="getRole === 1" :label="$t('organizations.step_2.minimum_soc')">
                <a-input-number
                    size="large"
                    type="number"
                    :min="0"
                    :max="50"
                    v-decorator="[
                    'soc_min',
                    {rules: [
                        {required: true, message: $t('messages.requiredField')}],
                    initialValue: detailOrganization.soc_min ? detailOrganization.soc_min : 0}]"
                >
                </a-input-number>
              </a-form-item>
              <div class="column-title">{{ $t('organizations.step_3.enable_inputs') }}</div>
              <a-form-item class="one-row sm-mb usbc-field" label="USB-C 100W">
                <a-switch @change="onUsbcInputChange" v-decorator="['is_input_usb_c_100w',
            {valuePropName: 'checked', initialValue: detailOrganization.is_input_usb_c_100w}]" />
              </a-form-item>
              <div v-if="isUSBcOn" class="custom-error-usbc has-error">
                <p class="custom-explain-edit ant-form-explain">{{ $t('organizations.step_3.usbcWarning') }}</p>
              </div>
              <a-form-item :label="$t('organizations.step_1.language')">
                <a-select
                    size="large"
                    :showArrow="true"
                    :placeholder="$t('organizations.select_from_list')"
                    :filter-option="false"
                    v-decorator="['language_id', {initialValue: initialLanguageValue}]">
                  <a-select-option v-for="item in languagesList" :value="item.id" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <div v-if="getRole === 1" class="column-title large-item">{{ $t('organizations.step_3.charge_mode_title') }}</div>
              <a-form-item v-if="getRole === 1" class="large-item">
                <a-radio-group class="charge-mode small-width"
                               v-decorator="['charge_mode', {initialValue: detailOrganization.charge_mode}]">
                  <a-radio-button :value="0">
                    {{ $t('organizations.step_3.charge_mode.highest') }}
                  </a-radio-button>
                  <a-radio-button :value="1">
                    {{ $t('organizations.step_3.charge_mode.lowest') }}
                  </a-radio-button>
                  <a-radio-button :value="2">
                    {{ $t('organizations.step_3.charge_mode.eco') }}
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <div v-if="getRole === 1" class="column-title large-item">{{ $t('organizations.step_3.ac_mode') }}</div>
              <a-form-item v-if="getRole === 1">
                <a-radio-group class="charge-mode ac-mode small-width" v-decorator="['ac_mode', {initialValue: 0}]">
                  <a-radio-button :value="0">
                    220VAC~50Hz
                  </a-radio-button>
                  <a-radio-button :value="1">
                    300VAC~3Hz
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item v-if="getRole === 1" class="one-row sm-mb" :label="$t('organizations.step_3.statistic_section')">
                <a-switch v-decorator="['statistic',
            {valuePropName: 'checked', initialValue:detailOrganization.statistic }]" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="form-action-btn">
          <a-button
              v-if="getRole === 1"
              @click="$emit('onClose')"
          >{{ $t('basics.cancel') }}</a-button>
          <a-button
              type="primary"
              @click="handleSubmit"
              :loading="loginBtn"
              :disabled="loginBtn || organizationStatus === 3">{{ $t('basics.save_changes') }}</a-button>
        </div>
      </a-tab-pane>
      <a-tab-pane key="automation" :tab="$t('basics.automation_tab')" v-if="getRole === 1">
        <a-form :form="form" class="detail-form">
          <a-row :class="{'not-active': organizationStatus === 3}">
            <a-col :lg="12">
              <div class="detail-info__column-title">{{$t('organizations.automation.connection_options')}}</div>
              <a-form-item :label="$t('organizations.automation.link_to_automatically_store_users')">
                <a-input
                    v-model="automationForm.url"
                    :class="{'validation-error': automationFormError.url}"
                    type="text"
                    size="large"
                    placeholder="example.com"
                    @keydown="automationFormError.url = false"
                >
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('organizations.automation.connection_type')">
                <a-select
                    v-model="automationForm.type"
                    size="large"
                    :showArrow="true"
                    :placeholder="$t('organizations.select_from_list')"
                    :filter-option="false"
                    :disabled="!automationForm.url"
                    >
                  <a-select-option v-for="item in connectionTypesList" :value="item.id" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <div v-if="automationForm.type !== 1 && automationForm.url" class="connection-data-wrapper">
                <a-form-item :label="$t('organizations.automation.file_name')">
                  <a-input
                      v-model="automationForm.connection_data.file_name"
                      :class="{'validation-error': automationFormError.file_name}"
                      type="text"
                      size="large"
                      placeholder="Badges.xlsx"
                      @keydown="automationFormError.file_name = false"
                  >
                  </a-input>
                </a-form-item>
                <div style="display: flex">
                  <a-form-item :label="$t('organizations.automation.username')">
                    <a-input
                        v-model="automationForm.connection_data.username"
                        :class="{'validation-error': automationFormError.username}"
                        type="text"
                        size="large"
                        placeholder="root"
                        @keydown="automationFormError.username = false"
                    >
                    </a-input>
                  </a-form-item>
                  <a-form-item
                    :label="$t('organizations.automation.password')"
                    v-if="!automationForm.connection_data.use_ssh"
                  >
                    <a-input
                        v-model="automationForm.connection_data.password"
                        :class="{'validation-error': automationFormError.password}"
                        :disabled="automationForm.connection_data.use_ssh && automationForm.type === 3"
                        type="password"
                        size="large"
                        placeholder="********"
                        @keydown="automationFormError.password = false"
                    >
                    </a-input>
                  </a-form-item>
                  <a-form-item :label="$t('organizations.automation.port')">
                    <a-input
                        v-model="automationForm.connection_data.port"
                        :class="{'validation-error': automationFormError.port}"
                        type="text"
                        size="large"
                        placeholder="22"
                        @keydown="automationFormError.port = false"
                    >
                    </a-input>
                  </a-form-item>
                </div>

                <div style="display: flex"  v-if="automationForm.type === 3">
                  <a-form-item
                    class="one-row sm-mb usbc-field"
                    :label="$t('organizations.automation.use_ssh')"
                  >
                    <a-switch
                      v-model="automationForm.connection_data.use_ssh"
                    />
                  </a-form-item>
                  <a-button
                    v-if="automationForm.ssh_key_exist"
                    type="primary"
                    @click="handleGetSshKey"
                    :loading="loadingSshKeyBtn"
                  >
                    {{ $t('organizations.automation.show_ssh_key') }}
                  </a-button>
                  <a-button
                    v-else
                    type="primary"
                    @click="handleGetSshKey"
                    :loading="loadingSshKeyBtn"
                  >
                    {{ $t('organizations.automation.create_ssh_key') }}
                  </a-button>
                </div>
              </div>
              <a-form-item :label="$t('organizations.automation.automation_time')">
                <a-time-picker
                    :value="automationForm.schedule_at ? moment(automationForm.schedule_at, 'HH:mm') : null"
                    :class="{'validation-error': automationFormError.schedule_at}"
                    size="large"
                    format="HH:mm"
                    style="width: 100%"
                    :disabled="!automationForm.url"
                    @change="onTimeUpdate"
                />
              </a-form-item>

              <a-button
               type="primary"
                @click="handleSubmitTestConnection"
               :loading="loadingTestConnectionBtn"
               :disabled="loadingTestConnectionBtn"
               style="margin-bottom: 10px"
              >
                {{ $t('organizations.automation.test_connection') }}
              </a-button>

              <div v-if="(testConnection.connectionResult === true)" style="margin-bottom: 10px">
                <div v-for="(testMassage, k) in testConnection.massages" :key="k" class="js-connection-success">
                  ✔ {{ testMassage }}
                </div>
                <div v-for="(testError, k) in testConnection.errors" :key="k" class="js-connection-error">
                  × {{ testError }}
                </div>
              </div>

              <div class="detail-info__column-title">{{$t('organizations.automation.import_rules')}}</div>
              <div class="automation-rules-wrapper">
                <h3>{{ $t('organizations.select_digit_range') }}</h3>
                <a-form-item v-for="(rule, idx) in automationForm.data" :key="idx" class="rule-wrapper">
                  <a-input
                      type="text"
                      size="large"
                      v-model="rule.from"
                      :placeholder="$t('organizations.from')"
                      :disabled="!automationForm.url"
                      @keydown="isNumber($event, rule)"
                      @focusout="focusOut($event, rule, 'from')"
                  >
                  </a-input>
                  <a-input
                      type="text"
                      size="large"
                      v-model="rule.to"
                      :placeholder="$t('organizations.to')"
                      :disabled="!automationForm.url"
                      @keydown="isNumber($event, rule)"
                      @focusout="focusOut($event, rule, 'to')"
                  >
                  </a-input>
                  <span>{{ $t('organizations.change_to') }}</span>
                  <a-input
                      type="text"
                      size="large"
                      v-model="rule.change"
                      :placeholder="$t('organizations.value')"
                      :disabled="!automationForm.url"
                      @keydown="isNumber($event, rule)"
                  >
                  </a-input>
                  <button
                      v-if="automationForm.data.length > 1"
                      class="delete-btn"
                      @click="deleteAutomationRule(idx)"
                  >
                    <close-icon/>
                  </button>
                </a-form-item>

                <a-button class="sm-button" :disabled="!automationForm.url" @click="addAutomationRule">
                  {{ $t('organizations.add_rule') }}
                </a-button>
              </div>
            </a-col>
            <a-col :lg="12">
              <div class="detail-info__column-title">{{$t('organizations.automation.parser_options')}}</div>
              <a-form-item :label="$t('organizations.automation.csv_separator')">
                <a-select
                    v-model="automationForm.parser_options.csv_separator"
                    size="large"
                    :showArrow="true"
                    :filter-option="false"
                    :disabled="!automationForm.url"
                    >
                  <a-select-option v-for="item in CSVSeparatorList" :value="item.id" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item :label="$t('organizations.automation.file_encoding')">
                <a-select
                    v-model="automationForm.parser_options.file_encoding"
                    size="large"
                    :showArrow="true"
                    :filter-option="false"
                    :disabled="!automationForm.url"
                    >
                  <a-select-option v-for="item in fileEncodingList" :value="item.id" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

        <div class="form-action-btn">
          <a-button @click="pushNfcBadges()" :disabled="pushNfcBtnDisabled" class="ant-btn-lg">{{ $t('bases.push_nfc') }}</a-button>
          <a-button @click="$emit('onClose')">{{ $t('basics.cancel') }}</a-button>
          <a-button
              type="primary"
              @click="handleSubmitAutomation"
              :loading="loginBtn"
              :disabled="loginBtn || organizationStatus === 3"
          >
            {{ $t('basics.save_changes') }}
          </a-button>
        </div>
      </a-tab-pane>
      <a-tab-pane key="template" :tab="$t('basics.template_tab')">

        <br />
        <div class="detail-info__name">
          <h2>
              {{$t('organizations.automation.last_import')}} : {{ getLastImportDate }}
          </h2>
        </div>

        <div class="table-container">
          <a-table
            rowKey="id"
            :locale={emptyText:emptyText}
            class="main-table"
            :columns="columns"
            :data-source="advancedData"
            :pagination="false"
          />
        </div>

        <br />

        <a-button
          type="primary"
          :loading="templateBtn"
          :disabled="templateBtn"
          @click="saveTemplate()"
        >
          {{ $t('organizations.save') }}
        </a-button>

      </a-tab-pane>
      <a-tab-pane key="advanced" :tab="$t('organizations.advanced_tab')" v-if="getRole === 1">
        <a-form :form="form" class="detail-form">
          <a-row :class="{'not-active': organizationStatus === 3}">
            <a-col :lg="12">
              <div class="detail-info__column-title">{{$t('organizations.card_reader_options')}}</div>
              <a-form-item :label="$t('organizations.reader_mode')">
                <a-select
                  size="large"
                  :showArrow="true"
                  :placeholder="$t('organizations.select_from_list')"
                  :filter-option="false"
                  v-decorator="['reader_mode', {initialValue: detailOrganization.reader_mode}]">
                >
                  <a-select-option v-for="item in readerModeList " :value="item.id" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item :label="$t('organizations.badge_reading_algorithm')">
                <a-select
                  size="large"
                  :showArrow="true"
                  :placeholder="$t('organizations.select_from_list')"
                  :filter-option="false"
                  v-decorator="['nfc_algorithm', {initialValue: detailOrganization.nfc_algorithm}]">
                >
                  <a-select-option v-for="item in UIDTranscodingAlgorithmList " :value="item.id" :key="item.id">
                    {{item.id}} : {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item :label="$t('organizations.badge_length')">
                <a-input
                  type="text"
                  size="large"
                  :showArrow="true"
                  :filter-option="false"
                  v-decorator="['badge_length', {initialValue: detailOrganization.badge_length}]">
                >
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :lg="12">
              <div class="detail-info__column-title">{{$t('organizations.reporting_settings')}}</div>
              <a-form-item class="one-row large-item sm-mb"  :label="$t('organizations.is_monitored')">
                <a-switch v-decorator="['is_monitored', {valuePropName: 'checked', initialValue: detailOrganization.is_monitored }]" />
              </a-form-item>
              <div class="detail-info__column-title">{{$t('organizations.pico_settings')}}</div>
              <a-form-item :label="$t('organizations.stop_blink_delay')">
                <a-input
                  type="text"
                  size="large"
                  :showArrow="true"
                  :filter-option="false"
                  v-decorator="['stop_blink_delay', {initialValue: detailOrganization.stop_blink_delay}]">
                >
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :lg="12">
            </a-col>
          </a-row>
        </a-form>
        <div class="form-action-btn">
          <a-button
              v-if="getRole === 1"
              @click="$emit('onClose')"
          >{{ $t('basics.cancel') }}</a-button>
          <a-button
              type="primary"
              @click="handleSubmit"
              :loading="loginBtn"
              :disabled="loginBtn || organizationStatus === 3">{{ $t('basics.save_changes') }}</a-button>
        </div>
      </a-tab-pane>
    </a-tabs>

    <a-modal :width="570" wrapClassName="warning-modal" :footer="false" v-model="showWarningPopup" @cancel="showWarningPopup = false">
      <div class="wrapper">
        <div class="warning-messages">
          <p v-if="!lockModeEnabled">{{ $t('organizations.warning_1_details') }}</p>
          <p class="usbc-msg" v-if="isUSBcOn">{{ $t('organizations.warning_2_details') }}</p>
        </div>
        <div class="switch-wrapper">
          <a-switch @change="userAgreeChange" v-decorator="[{ initialValue:false }]" />
          <p>{{ $t('organizations.read_agree') }}</p>
        </div>
      </div>
      <div class="btn-wrapper">
        <a-button @click="showWarningPopup = false">{{ $t('basics.cancel') }}</a-button>
        <a-button class="left-btn ant-btn ant-btn-primary" @click="submitForm" :disabled="!userAgree">Ok</a-button>
      </div>
    </a-modal>
    <a-modal :width="1016" wrapClassName="map-modal" :footer="false" v-model="showBigMap" @cancel="closeMap">
      <LocationMap :show="showMap" :map="map" ref="bigLocationMap" refer="bigLocationMap" @handleMapClick="handleMapClick" :location="location" :modalMap="true" />
    </a-modal>
    <a-modal :width="1016" wrapClassName="map-modal" :footer="false" v-model="showBigRadiusMap" @cancel="closeRadiusMap">
      <LocationMap :radiusMap="true" :circle="circle" :show="showRadiusMap" :map="map" ref="bigRadiusMap" refer="bigRadiusMap" :location="location"  :modalMap="true"/>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { requiredField, emailIsInvalid, minLength, maxLength, deleteOrganizationMassages, emailWasSent, domainIsNotValid } from '@/utils/messages';
import { countriesList } from '@/utils/countriesList';
import { picoLimit, timeUnits, metricUnits } from '@/utils/defaulVeriables';
import moreIcon from '@/assets/icons/More.svg?inline'
import closeIcon from '@/assets/icons/close.svg?inline'
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { latLng } from "leaflet";
import LocationMap from '../../../components/LocationMap/LocationMap.vue';
import {pushBadges} from "../../../api/organizations";

export default {
  name: 'DetailOrganizationForm',
  components: {
    moreIcon,
    closeIcon,
    LocationMap
  },
  data() {
    return {
      countriesList,
      requiredField,
      maxLength,
      emailIsInvalid,
      domainIsNotValid,
      minLength,
      picoLimit,
      timeUnits,
      metricUnits,
      deleteOrganizationMassages,
      emailWasSent,
      showMoreInfo: false,
      fetching: false,
      isTimeLimited: false,
      isAreaLimited: false,
      isActive: false,
      loginBtn: false,
      loadingTestConnectionBtn: false,
      loadingSshKeyBtn: false,
      showSSHKeyBtn: false,
      organizationAddress: '',
      addressResults: [],
      addressLoading: false,
      addressData: null,
      locationPicked: false,
      time: null,
      addressResultsEmpty: false,
      lockModeEnabled: true,
      isUSBcOn: false,
      nfc_algorithm: 1,
      reader_mode: 0,
      badge_length: null,
      stop_blink_delay: 10000,
      showWarningPopup: false,
      userAgree: false,
      canSwitchAreaToggle: true,
      map: {
        zoom: 13,
        center: latLng(50.454442264865214, 30.51689219566667),
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        mapOptions: {
          zoomSnap: 0.5,
          zoomControl: false
        },
        latitude: 50.454442264865214,
        longitude: 30.51689219566667
      },
      location: null,
      showMap: false,
      showBigMap: false,
      showBigRadiusMap: false,
      circle: null,
      selectedUsers: [],
      usersQuery: '',
      isFileUrl: false,
      activeTab: 'general',
      automationForm: {
        url: null,
        ssh_key_exist: false,
        type: 1,
        connection_data: {
          file_name: null,
          username: null,
          password: null,
          port: 22,
          use_ssh: false
        },
        parser_options: {
          csv_separator: 0,
          file_encoding: 0,
        },
        schedule_at: new Date(),
        data: [{from: null, to: null, change: null}]
      },
      automationFormError: {
        url: false,
        file_name: false,
        username: false,
        password: false,
        port: false,
        schedule_at: false,
      },
      pushNfcBtnDisabled: false,
      emptyText: this.$t('organizations.no_data'),
      requestTemplate: {},
      templateBtn: false,
      defaultTemplate: {
        id: 1,
        nfc: '-1',
        email: '-1',
        name: '-1',
        last_name: '-1',
      },
      testConnection: {
          connectionResult: false,
          errors: [],
          massages: [],
      }
    }
  },
  props: {
    canDelete: {
      type: Boolean,
      default: true
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  async beforeMount() {
    await this.$store.dispatch('languages/GetLanguagesList');
    this.fetchOrganizations();
  },
  mounted() {
    let modal = document.getElementsByClassName('modal-body')[0];
    if (modal) {
      modal.addEventListener('scroll', this.handleScroll);
    }
  },
  computed: {
    getLastImportDate() {
      if (this.detailOrganization.last_import_at) {
          try {
              return new Date(parseInt(`${this.detailOrganization.last_import_at}000`)).toLocaleString();
          } catch (e) {}
      }
      return '';
    },
    filteredUsers() {
      return this.usersQuery
          ? this.usersList.filter(el => el.name.toLowerCase().includes(this.usersQuery.toLowerCase()) || el.email.includes(this.usersQuery))
          : this.usersList;
    },
    usersList() {
      let orgList = this.$store.getters['users/getListForOrganization'];
      if (orgList) {
        return this.$store.getters['users/getListForOrganization'].map(el => {
          return {...el, value: el.id};
        });
      }
      return [];
    },
    computedManagers() {
      return this.usersList.length && this.detailOrganization && this.detailOrganization.admins
          ? this.usersList.filter(el => this.detailOrganization.admins.map(e => e.id).includes(el.id)).map(manager => {
            return {key: manager.id, label: manager.name, email: manager.email}
          })
          : [];
    },
    organizationsList() {
      return this.$store.getters['organizations/getOrganizations']
    },
    languagesList() {
      return this.$store.getters['languages/getLanguagesList'];
    },
    connectionTypesList() {
      return [{id: 1, name: 'HTTP'}, {id: 2, name: 'FTP'}, {id: 3, name: 'SFTP'}];
    },
    CSVSeparatorList() {
      return [{id: 0, name: this.$t('organizations.automation.default')}, {id: 1, name: ';'}, {id: 2, name: ','}];
    },
    fileEncodingList() {
      return [{id: 0, name: 'UTF-8'}, {id: 1, name: 'CP-1252'}];
    },
    UIDTranscodingAlgorithmList() {
      return [{id: 0, name: 'Raw'}, {id: 1, name: 'Common'}, {id: 2, name: 'Byte Cast'}, {id: 3, name: 'Sanofi'}, {id: 4, name: 'Reverse Byte Cast'},
              {id: 5, name: 'Reverse Byte Raw'}, {id: 6, name: 'Wiegand 26bits'}, {id: 7, name: 'PWC'}, {id: 8, name: 'CA'}, {id: 9, name: 'Unused'},
              {id: 10, name: 'Raw'}, {id: 11, name: 'Unused'}, {id: 12, name: 'Unused'}, {id: 13, name: 'Unused'}, {id: 14, name: 'Unused'} ];
    },
    readerModeList() {
      return [{id: 0, name: 'HF14A'}, {id: 1, name: 'LFEM410x'}, {id: 2, name: 'LFHID'}, {id: 3, name: 'LFINDALA'}, {id: 4, name: 'LFHITAG'}];
    },
    getRole() {
      return parseInt(this.$store.getters['getRole']);
    },
    detailOrganization() {
      return this.$store.getters['detailOrganization/getOrganizationDetails']
    },
    organizationStatus() {
      return this.$store.getters['detailOrganization/getOrganizationStatus']
    },
    emailRecoveryPasswordState() {
      return this.$store.getters['getEmailSentState']
    },
    showRadiusMap() {
      return this.isAreaLimited && this.canSwitchAreaToggle;
    },
    initialLanguageValue() {
      return this.detailOrganization && this.detailOrganization.language ? this.detailOrganization.language.id : null;
    },
    initialConnectionType() {
      return this.automationForm?.type || 1;
    },
    automationUrl() {
      return this.automationForm.url;
    },
    columns() {
      return [
        {
          title: 'NFC ID',
          dataIndex: 'nfc',
          key: 'nfc',
          width: '25%',
          class: 'js-table-class',
          customRender: data => {
            return this.generateCustomRenderSelect('nfc');
          }
        },
        {
          title: 'EMAIL',
          dataIndex: 'email',
          key: 'email',
          width: '25%',
          class: 'js-table-class',
          customRender: data => {
            return this.generateCustomRenderSelect('email');
          }
        },
        {
          title: 'NAME',
          dataIndex: 'name',
          key: 'name',
          class: 'js-table-class',
          width: '25%',
          customRender: data => {
            return this.generateCustomRenderSelect('name');
          }
        },
        {
          title: 'LAST NAME',
          dataIndex: 'last_name',
          key: 'last_name',
          class: 'js-table-class',
          customRender: data => {
            return this.generateCustomRenderSelect('last_name');
          }
        }
      ]
    },
    advancedData() {
      let data =  this.getCurrentTamplateImportData(
          JSON.parse(JSON.stringify(this.defaultTemplate))
      );
      this.setDataToTemplate(data);
      return [data];
    }
  },
  watch: {
    detailOrganization: function(){
      if (this.detailOrganization) {
        this.$store.dispatch('users/GetList', {organization_id: this.detailOrganization.id});

        this.lockModeEnabled = this.detailOrganization.is_lock_mode;
        this.isUSBcOn = this.detailOrganization.is_input_usb_c_100w;
        this.isAreaLimited = this.detailOrganization.is_area_limited;
        this.isTimeLimited = this.detailOrganization.is_time_limited;
        this.isFileUrl = this.detailOrganization.file ? !!this.detailOrganization.file.url : false;
        this.canSwitchAreaToggle = this.detailOrganization.is_gps_on;
        this.circle = {
          center: latLng(this.detailOrganization.location.latitude, this.detailOrganization.location.longitude),
          radius: 50
        }
        this.showMap = true;
        this.location = latLng(this.detailOrganization.location.latitude, this.detailOrganization.location.longitude);
        this.$nextTick(() => {
          this.$refs.locationMap.$refs.locationMap.mapObject.setView([this.detailOrganization.location.latitude, this.detailOrganization.location.longitude], 13);
        })

        this.nfc_algorithm = this.detailOrganization.nfc_algorithm;
        if (this.detailOrganization.file) {
          this.automationForm.url = this.detailOrganization.file.url;
          if (this.detailOrganization.file.data) {
            this.automationForm.data = this.detailOrganization.file.data;
          }
          this.automationForm.ssh_key_exist = this.detailOrganization.file.ssh_key_exist;
          this.automationForm.type = this.detailOrganization.file.type;
          if (this.detailOrganization?.file?.connection_data) {
            this.automationForm.connection_data = this.detailOrganization.file.connection_data;
          }
          this.automationForm.schedule_at = new Date(this.detailOrganization.file.schedule_at)
              .toLocaleString('en-GB', {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})
              .split(', ')[1]
              .slice(0, -3);
          if ( this.detailOrganization.file.parser_options )
          {
            if ( this.detailOrganization.file.parser_options.csv_separator )
              this.automationForm.parser_options.csv_separator = this.detailOrganization.file.parser_options.csv_separator;
            if ( this.detailOrganization.file.parser_options.file_encoding )
              this.automationForm.parser_options.file_encoding = this.detailOrganization.file.parser_options.file_encoding;
          }
        }
      }
    },
    showRadiusMap() {
      if (this.showRadiusMap) {
        let radius;
        if (this.detailOrganization.area_limit?.unit == "km") {
          radius = this.detailOrganization.area_limit.radius + "000";
        } else {
          radius = this.detailOrganization.area_limit ? this.detailOrganization.area_limit.radius : 1000;
        }
        this.circle = {
          center: latLng(this.detailOrganization.location.latitude, this.detailOrganization.location.longitude),
          radius: +radius
        };
        this.$nextTick(() => {
          this.$refs.radiusMap.$refs.radiusMap.mapObject.setView([this.detailOrganization.location.latitude, this.detailOrganization.location.longitude], 13);
        });
      }
    },
    automationUrl(url) {
      if (!url) {
        this.automationForm.schedule_at = null;
      }
    }
  },
  methods: {
    moment,
    handleScroll () {
      let openedSelects = document.getElementsByClassName('ant-select ant-select-open');
      let selectDropdowns = document.getElementsByClassName('ant-select-dropdown');

      openedSelects.forEach(element => {
        if (element) {
          element.classList.remove(...['ant-select-open', 'ant-select-focused']);
        }
      })

      selectDropdowns.forEach(element => {
        if (element) {
          element.style.display = 'none';
        }
      })
    },
    handleChange(value) {
      //console.log(value);
    },
    isNumber(e) {
      e = (e) ? e : window.event;
      var charCode = (e.which) ? e.which : e.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    focusOut(e, rule, type) {
      if (type === 'from' && rule.to && parseInt(e.target.value) > rule.to) {
        rule.from = rule.to;
      }
      if (type === 'to' && rule.from && parseInt(e.target.value) < rule.from) {
        rule.to = rule.from;
      }
    },
    onTimeUpdate(e) {
      this.automationFormError.schedule_at = false;
      this.automationForm.schedule_at = e;
    },
    fetchOrganizations(value) {
      this.fetching = true;
      this.$store.dispatch('organizations/GetOrganizationDropdownList', value)
          .then(response => {
            const data = response.data.map(item => ({id: item.id, name: item.name}));
            this.$store.commit('organizations/SET_ORGANIZATIONS_LIST', data);
            this.fetching = false;
          })
    },
    gpsChange(e) {
      if(e == false) {
        this.form.setFieldsValue({
          is_area_limited: false
        });
        this.canSwitchAreaToggle = false;
        this.isAreaLimited = false;
      } else {
        this.canSwitchAreaToggle = true;
      }
    },
    onChangeSwitcher(field, e){
      switch (field) {
        case 'time_limit.duration':
          this.isTimeLimited = !this.isTimeLimited
          break;
        case 'area_limit.center':
          this.isAreaLimited = e
          break;
        case 'is_active':
          this.detailOrganization.is_active = !this.detailOrganization.is_active
          break;
        default:
          break;
      }

      this.$nextTick(() => {
        this.form.validateFields([field], { force: true });
      });
    },
    submitForm() {
      this.form.validateFields((err, values) => {
        if (values.time_limit.duration === null) {
          values.time_limit = null;
        }
        if (values.location.value) {
          values.location = values.location.value;
        }
        if (values.admins.length) {
          values.admins = values.admins.map(el => el.key);
        }
        if (values.profiles) {
          values.profiles = values.profiles.map(profile => {
            return profile.key;
          });
        }
        values.location.latitude = values.latitude;
        values.location.longitude = values.longitude;
        delete values.latitude;
        delete values.longitude;
        this.$store.dispatch('detailOrganization/UpdateOrganization', {
          id: this.detailOrganization.id,
          data: values
        })
            .then(() => {
              this.showWarningPopup = false;
              this.$message.success('Organization is saved successfully');
              this.$emit('onClose');
              this.loginBtn = false;
            })
            .catch(error => {
              for (const [key, value] of Object.entries(error.response.data.error.errors)) {
                this.form.setFields({
                  [key]: {
                    value: '',
                    errors: [new Error(value)]
                  }
                });
              }
              this.loginBtn = false;
              this.showWarningPopup = false;
            });
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.loginBtn = true
      this.form.validateFields((err, values) => {
        if (values.time_limit.duration === null) {
            values.time_limit = null
        }
        if(values.location.value) {
          values.location = values.location.value;
        }
        if (!err) {
          if (values.is_lock_mode === false || values.is_input_usb_c_100w === true) {
            this.showWarningPopup = true;
            this.loginBtn = false;
            return;
          }
          this.submitForm();
        } else {
          this.loginBtn = false
        }
      });
    },
    validateDomains(rule, value, callback) {
      try {
      const reg = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;
      const restrictedDomains = [
        'gmail.com',
        'outlook.com',
        'mail.com',
        'yahoo.com',
        'aol.com',
        'lycos.com'
      ]
      value.forEach(domain => {
        if (!reg.test(domain)) {
          throw new Error(this.$t('messages.domainIsNotValid'));
        }
        if(restrictedDomains.includes(domain)){
          throw new Error(this.$t('messages.domainIsRestricted'));
        }
      })
      } catch (error) {
        callback(error);
        return
      }
      callback()
    },
    validateProfiles(rule, value, callback) {
      if (value) {
        value.forEach(profile => {
          if (profile.label.length > 12) {
            callback(this.$t('messages.profileIsTooLong'));
          }
        })
        if (value.length > 5) {
          callback(this.$t('messages.profileIsTooLong'));
        } else if (value.length <= 5) {
          callback();
        }
      }
    },
    validateUrl(rule, value, callback) {
      if (value) {
        try {
          new URL(value);
          this.isFileUrl = true;
        } catch (error) {
          this.isFileUrl = false;
          callback(new Error(this.$t('messages.invalidUrl')));
        }
      }

      callback();
    },
    validateLogo(rule, value, callback) {
      if (!value) {
        callback();
        return true;
      }

      let img = new Image();
      let $this = this;
      img.onload = function() {
        if (!/\.(png)$/.test(value)) {
          callback(new Error($this.$t('messages.invalidPngFormat')));
        }
        if (this.width > 200 || this.height > 200) {
          callback(new Error($this.$t('messages.invalidImageSize')));
        }
      };
      img.onerror = function() {
        callback(new Error($this.$t('messages.invalidImageUrl')));
      }
      img.src = value;

      callback();
      return true;
    },
    deleteOrganization() {
      this.$confirm({
        centered: true,
        content: this.$t('messages.deleteOrganizationMassages'),
        okText: this.$t('basics.yes'),
        cancelText: this.$t('basics.cancel'),
        width: 490,
        closable: true,
        onOk: () => {
          this.$store.dispatch('detailOrganization/DeleteOrganization', this.detailOrganization.id)
          .then(() => {
            this.$message.success(this.$t('organizations.organization_deleted'))
            this.$emit('onClose')
          })
          .catch(error => {
            this.$message.error(error.response.data.error.errors);
          })
        },
        onCancel: () => this.showMoreInfo = false
      })
    },
    searchLocation(e) {
      this.organizationAddress = e;
      if (e.length < 1) {
        this.addressResults = [];
        this.addressLoading = false;
      } else if (e.length > 2) {
            if (this.time) {
                clearTimeout(this.time);
            }
            this.time = setTimeout( () => {
              this.addressLoading = true;
              !this.locationPicked ? this.searchRequest(e) : null
            }, 1000);
      }
    },
    searchRequest(value) {
      const provider = new OpenStreetMapProvider();
      provider.search({ query: value })
          .then(results => {
            this.addressLoading = false
            this.addressResults = results
            this.addressResultsEmpty = false;
            if (results.length === 0) {
              this.addressResultsEmpty = true;
            }
          }).catch(() => { this.addressLoading = false })
    },
    setLocation(value) {
      this.showMap = true;
      const location = value.key.split('|');
      this.location = latLng(location[1], location[0]);
      this.form.setFieldsValue({
        location: {
          label: location[2]
        },
          latitude: location[1],
          longitude: location[0]
      });
      this.$nextTick(() => {
        this.$refs.locationMap.$refs.locationMap.mapObject.setView([location[1], location[0]], 13);
      })
      if(this.$refs.radiusMap) {
        this.$refs.radiusMap.$refs.radiusMap.mapObject.setView([location[1], location[0]], 13); 
        this.circle.center = latLng(location[1], location[0]);
      }
    },
    lockModeChange(e) {
      this.lockModeEnabled = e;
    },
    onUsbcInputChange(e) {
      this.isUSBcOn = e;
    },
    userAgreeChange(e) {
      this.userAgree = e;
    },
    handleMapClick(e) {
      let {latlng} = e;
      this.location = latLng(latlng.lat, latlng.lng);
      this.circle.center = latLng(latlng.lat, latlng.lng);
      if (this.location.lat && this.location.lng) {
        this.$refs.radiusMap.$refs.radiusMap.mapObject.setView([latlng.lat, latlng.lng], 13); 
      }
      this.form.setFieldsValue({
        latitude: latlng.lat,
        longitude: latlng.lng
      });
    },
    setLatitude(e) {
      const value = e.target.value;
      if (this.detailOrganization.location.longitude) {
        this.location = latLng(value, this.form.getFieldValue("longitude"));
        this.$refs.locationMap.$refs.locationMap.mapObject.setView([value, this.form.getFieldValue("longitude")], 13);
        if(this.$refs.radiusMap) {
          this.$refs.radiusMap.$refs.radiusMap.mapObject.setView([value, this.form.getFieldValue("longitude")], 13); 
          this.circle.center = latLng(value, this.form.getFieldValue("longitude"));
        }
      }
    },
    setLongitude(e) {
      const value = e.target.value;
      if (this.detailOrganization.location.latitude) {
        this.location = latLng(this.form.getFieldValue("latitude"), value);
        this.$refs.locationMap.$refs.locationMap.mapObject.setView([this.form.getFieldValue("latitude"), value], 13);
        if(this.$refs.radiusMap) {
          this.$refs.radiusMap.$refs.radiusMap.mapObject.setView([this.form.getFieldValue("latitude"), value], 13); 
          this.circle.center = latLng(this.form.getFieldValue("latitude"), value);
        }
      }
    },
    openMap() {
      this.showBigMap = true;
      if (this.location.lat && this.location.lng) {
        this.$nextTick(() => {
          this.$refs.bigLocationMap.$refs.bigLocationMap.mapObject.setView([this.location.lat, this.location.lng], 13); 
        })
      }
    },
    closeMap() {
      this.showBigMap = false;
      if (this.location.lat && this.location.lng) {
        this.$refs.locationMap.$refs.locationMap.mapObject.setView([this.location.lat, this.location.lng], 13); 
      }
    },
    setRadiusUnits(e) {
      if (e == "km") {
        const radius = this.form.getFieldValue("area_limit").radius + "000";
        this.circle.radius = +radius;
      } else {
        this.circle.radius = this.form.getFieldValue("area_limit").radius;
      };
    },
    setRadiusValue(e) {
      if (this.form.getFieldValue("area_limit").unit === "km") {
        const radius = e + "000";
        this.circle.radius = +radius;
      } else {
        this.circle.radius = e;
      }
    },
    openRadiusMap() {
      this.showBigRadiusMap = true;
      if (this.location.lat && this.location.lng) {
        this.$nextTick(() => {
          this.$refs.bigRadiusMap.$refs.bigRadiusMap.mapObject.setView([this.location.lat, this.location.lng], 13); 
        })
      }
    },
    closeRadiusMap() {
      this.showBigRadiusMap = false;
      if (this.location.lat && this.location.lng) {
        this.$refs.radiusMap.$refs.radiusMap.mapObject.setView([this.location.lat, this.location.lng], 13); 
      }
    },
    addAutomationRule() {
      this.$set(this.automationForm.data, this.automationForm.data.length, {from: null, to: null, change: null});
    },
    deleteAutomationRule(idx) {
      this.$delete(this.automationForm.data, idx);
    },
    handleSubmitAutomation() {
      if (this.automationForm.url && !this.automationForm.schedule_at) {
        this.$message.error('You need to set time');    // TODO translate
        this.automationFormError.schedule_at = true;
        return false;
      }

      if (this.automationForm.type !== 1) {
        this.automationFormError.file_name = !this.automationForm.connection_data.file_name;
        this.automationFormError.username = !this.automationForm.connection_data.username;
        this.automationFormError.password = (!this.automationForm.connection_data.password && !this.automationForm.connection_data.use_ssh) || (this.automationForm.connection_data.use_ssh && !this.automationForm.ssh_key_exist);
        this.automationFormError.port = !this.automationForm.connection_data.port;

        if (Object.values(this.automationFormError).some(v => v === true)) return false;
      }

      if ( this.automationForm.parser_options.csv_separator == 0 )
        this.automationForm.parser_options.csv_separator = null;
      if ( this.automationForm.parser_options.file_encoding == 0 )
        this.automationForm.parser_options.file_encoding = null;

      this.automationForm.schedule_at = moment(this.automationForm.schedule_at, ['h:m a', 'H:m']);

      this.$store.dispatch('detailOrganization/UpdateOrganizationAutomation', {
        id: this.detailOrganization.id,
        data: this.automationForm
      })
          .then(() => {
            this.showWarningPopup = false;
            this.$message.success('Organization automation data is saved successfully');    // TODO translate
            this.$emit('onClose');
            this.loginBtn = false;
          })
          .catch(error => {
            for (const [key, _] of Object.entries(error.response.data.error.errors)) {
              this.automationFormError[key] = true;
            }
            this.loginBtn = false;
            this.showWarningPopup = false;
          });
    },
    pushNfcBadges() {
      if (this.detailOrganization) {
        this.pushNfcBtnDisabled = true;
        pushBadges(this.detailOrganization.id);
        this.$message.success(this.$t('bases.push_nfc_request_sent'))
      }
    },
    saveTemplate() {
      this.templateBtn = true
      this.$confirm({
        centered: true,
        content: this.$t('messages.import_template_change'),
        okText: this.$t('basics.yes'),
        cancelText: this.$t('basics.cancel'),
        width: 490,
        closable: true,
        onOk: () => {
          this.$store.dispatch('detailOrganization/UpdateImportTemplate', {
            id: this.detailOrganization.id,
            data: this.requestTemplate
          }).then((data) => {
            this.$message.success('Successful'); // TODO translate
            setTimeout(() => {
             this.templateBtn = false;
            }, 1000);
            this.$store.dispatch('detailOrganization/GetOrganization', this.detailOrganization.id)
          }).catch(error => {
            if (error.response.status === 422) {
              Object.entries(error.response.data.error.errors).forEach(([key, value]) => {
                this.$message.error(value.join("\n"));
              });
            } else {
              this.$message.error('Not successful');
            }

            setTimeout(() => {
              this.setDataToTemplate(
                this.getCurrentTamplateImportData(
                  JSON.parse(JSON.stringify(this.defaultTemplate))
                )
              );
              this.templateBtn = false;
            }, 2000);

          });
        },
        onCancel: () => {
          this.setDataToTemplate(
              this.getCurrentTamplateImportData(
                  JSON.parse(JSON.stringify(this.defaultTemplate))
              )
          );
          this.templateBtn = false;
        }
      });
    },
    getCurrentTamplateImportData(data) {
      if (this.detailOrganization && this.detailOrganization.import_template) {
        let template = this.detailOrganization.import_template.template;
        if (typeof template === 'object' ) {
          data.email = template.email ? template.email : data.email
          data.nfc = template.nfc ? template.nfc : data.nfc
          data.name = template.name ? template.name : data.name
          data.last_name = template.last_name ? template.last_name : '-1'
        }
      }
      return data;
    },
    changeTemplate(selector, data) {
      this.requestTemplate[selector] = data;
    },
    setDataToTemplate(data) {
      this.requestTemplate = data;
    },
    generateOptions() {
      let col = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
      let colObj = [{value: '-1', label: '--- EMPTY ---', key: 0,}];
      col.forEach((value,index) => {
        colObj.push({value: value, label: value, key: (index + 1)});
      });
      return colObj;
    },
    generateCustomRenderSelect(selector) {
      return this.$createElement("a-select", {
        props: {
          size: 'large',
          showArrow: true,
          dropdownMatchSelectWidth: false,
          defaultValue: this.requestTemplate[selector],
          value: this.requestTemplate[selector],
          options: this.generateOptions()
        },
        on: {
          'change': (data) => {
            this.changeTemplate(selector, data)
          }
        }
      });
    },
    handleSubmitTestConnection() {
      this.loadingTestConnectionBtn = true;
      this.testConnection.connectionResult = false;
      if (!this.automationForm.url) {
        this.$message.error(this.$t('organizations.automation.not_url'));
        this.loadingTestConnectionBtn = false;
        return false;
      }

      this.$store.dispatch('detailOrganization/TestConnectionAutomation', {
        id: this.detailOrganization.id,
        data: this.automationForm
      }).then((data) => {
        this.testConnection.connectionResult = true;
        this.testConnection.massages = data.massages
        this.testConnection.errors = data.errors
        this.loadingTestConnectionBtn = false;
      }).catch(error => {
        this.testConnection.connectionResult = true;
        this.testConnection.errors = [this.$t('organizations.automation.connection_timeout')];
        this.loadingTestConnectionBtn = false;
      });
    },
    handleGetSshKey() {
      this.loadingSshKeyBtn = true;
      this.$store.dispatch('detailOrganization/GetSshKey', {
        id: this.detailOrganization.id,
      }).then((data) => {
        this.loadingSshKeyBtn = false;
        this.automationForm.ssh_key_exist = true;
        this.$info({
          title: this.$t('organizations.automation.ssh_key'),
          content: `${data.data}`,
          okText: this.$t('basics.yes'),
          width: 490,
          closable: true,
          maskStyle: {'margin-left': '0px !important'},
          class: 'js-show-ssh-key',
          onOk: () => {},
        })
      }).catch(error => this.loadingSshKeyBtn = false);
    },
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style lang="less" scoped>
.location-error {
  color: red;
  margin-top: 5px;
}
.custom-error-usbc {
  position: relative;
  width: auto;
}
.custom-explain-edit {
  position: absolute;
  top: -60px;
}
.create-organization-form {
  .ant-form-item {
    &.usbc-field {
      margin-bottom: 60px;
    }
  }
}
.custom-error-lock-mode {
  position: relative;
}
.custom-explain {
  position: absolute;
  top: -56px;
}
.lock-mode-filed {
  margin-bottom: 40px;
}
.usbc-field {
  margin-bottom: 55px;
}
.warning-modal {
  .ant-modal-body {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
    }
    .usbc-msg {
      margin-top: 10px;
    }
    .switch-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-left: 10px;
      p {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
  .btn-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .left-btn {
      margin-left: 50px;
    }
  }
}
</style>
<style lang="less">
.working-radius {
  .ant-form-explain {
    min-width: 280px !important;
  }
}
  .custom-lable {
    font-size: 15px;
  }
  .user-domains-field {
    margin-bottom: 125px;
    .ant-form-item-control-wrapper {
      top: 80px;
    }
    .custom-explain {
      top: -75px;
    }
    #user_domains {
      margin-top: 10px;
    }
    @-moz-document url-prefix() {
      .custom-explain {
        top: -65px;
        width: 240px;
      }
      #user_domains {
        margin-top: 5px;
      }
    }
    .ant-form-explain {
      position: absolute;
    }
    .ant-form-explain {
      margin-top: 0;
    }
  }
</style>
<style lang="less" scoped>
.validation-error::v-deep {
  border-color: #FF5D53;

  input {
    border-color: #FF5D53;
  }
}

.automation-rules-wrapper::v-deep {
  .rule-wrapper {
    max-width: none;

    .ant-form-item-children {
      display: flex;
      align-items: center;

      input:not(:first-child) {
        margin-left: 15px;
      }

      span {
        margin-left: 15px;
        text-transform: lowercase;
        white-space: nowrap;
      }
    }
  }

  .sm-button {
    height: auto;
    padding: 7px 15px;
    font-size: 12px;
  }
}
</style>
<style lang="less">
td.js-table-class div.ant-select {
    width: 100% !important;
}
div.js-connection-success {
    color: #28a745 !important;
}
div.js-connection-error {
    color: #dc3545 !important;
}
</style>
